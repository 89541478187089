.Projects {
    &__img {
        width: 100%;
        &:hover {
            cursor: pointer
        }
    }

    &__projects {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        margin-top: 2rem;

        @media (max-width: 1100px) {
            grid-template-columns: 1fr;
        }
    }
}