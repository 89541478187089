.About {

    &__img {
        width: 30rem;
        height: 30rem;

        @media (max-width: 1100px) {
            width: 100%;
            height: 100%;
        }
    }

    &__desc {
        font-size: 1.5rem;
        color: #221230;
        text-align: center;
        padding: 4rem;

        @media (max-width: 1100px) {
            padding: 1rem;
        }
    }

    &__wrapper {
        display: flex;
        text-align: center;

        @media (max-width: 1100px) {
            display: block;
        }
    }
}