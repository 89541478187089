@font-face {
    font-family: "Inter";
    src: url("./fonts/Inter/Inter-VariableFont_slnt\,wght.ttf");
}

.App, body {
    font-family: Inter !important;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}